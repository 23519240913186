<template>
	<div>
		<div class="main" style="padding-left: 16px; padding-right: 16px;">
			<div class="filterWrapper">
				<WorkersAutocompleter  @input="input($event)" :workers="workers" />
				<v-select class="ml-5"
					v-model="selectedProject"
					:items="newProjects"
					:label="lviews.projects"
					item-text="name"
					item-value="id"
				></v-select>				
			</div>


			<v-list-group
				:value="open"
			>
				<template v-slot:activator>
					<v-list-item-title class="primary--text">{{lviews.present}}</v-list-item-title>
				</template>
				<v-divider class="primary"></v-divider>
				
				<v-list dense>
					<template v-for="item in displayPresentWorkers" 
					>
						<v-list-item class="pt-2 pl-1"
							:key="item.identifier"
						>
							<v-list-item-icon class="mr-1">
								<v-badge dot offset-x="-5" offset-y="15" color="green" ></v-badge>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-if="selectedProject==-1" class="font-weight-regular"><b>{{item.fullName}}</b> ({{item.projectName}})</v-list-item-title>
								<v-list-item-title v-else class="font-weight-regular"><b>{{item.fullName}}</b></v-list-item-title>

								<v-list-item-subtitle>
									<span v-if="item.currentRecord != null">{{ item.currentRecord.time }} ({{lviews.entry}} </span>
									<span v-if="item.currentRecord != null && item.currentRecord.date== yesterdayDate().toISOString().substr(0, 10)">{{lviews.yesterday}}</span>
									<span v-if="item.currentRecord != null && item.nextRecord != null">) - </span>
									<span v-if="item.nextRecord != null">{{ item.nextRecord.time }} ({{lviews.planned}} {{ getTypeName(item.nextRecord.type)}}</span>
									<span v-if="item.nextRecord != null && item.nextRecord.date== tomorrowDate().toISOString().substr(0, 10)"> {{lviews.nextDay}}</span>
									<span v-if="item.currentRecord != null || item.nextRecord != null">)</span>
								</v-list-item-subtitle>
							</v-list-item-content>

							<!-- Actions mobile -->
							<v-list-item-action class="hidden-md-and-up ma-0">
								<v-menu offset-y 
									v-if="item.permissions.add || item.permissions.change"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
									</template>

									<v-list dense class="pa-0">
										<v-list-item v-if="item.permissions.change" class="pl-2" router-link :to="{name: 'addLeaving', params: {worker: item, edit: true}}">
											<v-list-item-icon class="mr-2">
												<v-icon dense>mdi-pencil</v-icon>
											</v-list-item-icon>
											<v-list-item-title>{{lviews.edit}}</v-list-item-title>
										</v-list-item>

										<v-list-item v-if="item.permissions.add && item.nextRecord==null" class="pl-2" router-link :to="{name: 'addLeaving', params: {worker: item},  edit: false}">
											<v-list-item-icon class="mr-2">
												<v-icon dense class="red--text">mdi-stop-circle</v-icon>
											</v-list-item-icon>

											<v-list-item-title class="red--text">{{lviews.addLeaving}}</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-list-item-action>

							<!-- Actions desktop -->
							<v-list-item-action class="hidden-sm-and-down my-0">
								<v-tooltip bottom v-if="item.permissions.change"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="secondary"
											icon
											v-bind="attrs"
											v-on="on"
											router-link :to="{name: 'addLeaving', params: {worker: item, edit: true }}"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<span>{{lviews.edit}}</span>
								</v-tooltip>
							</v-list-item-action>

							<v-list-item-action v-if="item.permissions.add && item.nextRecord==null" class="hidden-sm-and-down ma-0">
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<router-link :to="{name: 'addLeaving', params: {worker: item,  edit: false}}">
											<v-btn
												color="secondary"
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon class="red--text">mdi-stop-circle</v-icon>
											</v-btn>
										</router-link>
									</template>
									<span>{{lviews.addLeaving}}</span>
								</v-tooltip>
							</v-list-item-action>
						</v-list-item>
						<v-divider :key="'divider-' + item.identifier"></v-divider>
					</template>
				</v-list>
			</v-list-group>

			<v-list-group
				:value="open"
				class="mt-7"
			>
				<template v-slot:activator>
					<v-list-item-title class="primary--text">{{lviews.absent}}</v-list-item-title>
				</template>
				<v-divider class="primary"></v-divider>
				
				<v-list dense>
					<template v-for="item in displayAbsentWorkers"
					>
						<v-list-item class="pt-2 pl-1"
							:key="item.identifier" 
						>
							<v-list-item-icon class="mr-1">
								<v-badge dot offset-x="-5" offset-y="15" color="red" ></v-badge>
							</v-list-item-icon>

							<v-list-item-content>
								<v-list-item-title v-if="selectedProject==-1" class="font-weight-regular"><b>{{item.fullName}}</b> ({{item.projectName}})</v-list-item-title>
								<v-list-item-title v-else class="font-weight-regular"><b>{{item.fullName}}</b></v-list-item-title>

								<v-list-item-subtitle>
									<span v-if="item.currentRecord != null">{{ item.currentRecord.time }} ({{lviews.leave}}</span>
									<span v-if="item.currentRecord != null && item.nextRecord != null">) - </span>
									<span v-if="item.nextRecord != null">{{ item.nextRecord.time }} ({{lviews.planned}} {{ getTypeName(item.nextRecord.type)}}</span>
									<span v-if="item.nextRecord != null && item.nextRecord.date== tomorrowDate().toISOString().substr(0, 10)"> {{lviews.nextDay}}</span>
									<span v-if="item.currentRecord != null || item.nextRecord != null">)</span>
								</v-list-item-subtitle>
							</v-list-item-content>

							<!-- Mobile actions -->
							<v-list-item-action class="hidden-md-and-up ma-0">
								<v-menu offset-y
									v-if="item.permissions.add && (item.nextRecord==null || item.nextRecord.type==1) || item.permissions.change && item.nextRecord"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
										</template>
									<v-list dense class="pa-0">
										<v-list-item v-if="item.permissions.change && item.nextRecord" class="pl-2" router-link :to="{name: 'addLeaving', params: {worker: item, edit: true}}">
											<v-list-item-icon class="mr-2">
												<v-icon dense>mdi-pencil</v-icon>
											</v-list-item-icon>
											<v-list-item-title>{{lviews.edit}}</v-list-item-title>
										</v-list-item>
		
										<v-list-item 
											class="pl-2" 
											router-link 
											:to="{name: 'addLeaving', params: {worker: item, edit: false}}"
											v-if="item.permissions.add && (item.nextRecord==null || item.nextRecord.type==1)"
										>
											<v-list-item-icon class="mr-2">
												<v-icon dense class="green--text">mdi-play-circle</v-icon>
											</v-list-item-icon>
											<v-list-item-title class="green--text">{{lviews.addEntering}}</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-list-item-action>

							<!-- Desktop actions --> 
							<v-list-item-action class="hidden-sm-and-down my-0">
								<v-tooltip bottom v-if="item.permissions.change && item.nextRecord"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											color="secondary"
											icon
											v-bind="attrs"
											v-on="on"
											router-link :to="{name: 'addLeaving', params: {worker: item, edit: true }}"
										>
											<v-icon>mdi-pencil</v-icon>
										</v-btn>
									</template>
									<span>{{lviews.edit}}</span>
								</v-tooltip>
							</v-list-item-action>

							<v-list-item-action v-if="item.permissions.add && (item.nextRecord==null || item.nextRecord.type==1)" class="hidden-sm-and-down ma-0">
								<v-tooltip bottom >
									<template v-slot:activator="{ on, attrs }">
										<router-link :to="{name: 'addLeaving', params: {worker: item, edit: false}}">
											<v-btn
												color="secondary"
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon class="green--text">mdi-play-circle</v-icon>
											</v-btn>
										</router-link>
									</template>
									<span>{{lviews.addEntering}}</span>
								</v-tooltip>
							</v-list-item-action>
						</v-list-item>
						<v-divider :key="'divider-' + item.identifier"></v-divider>
					</template>
				</v-list>
			</v-list-group>

			<!-- Unknown status workers -->
			<v-list-group
				:value="open"
				class="mt-7"
			>
				<template v-slot:activator>
					<v-list-item-title class="primary--text">{{lviews.unknownStatus}}</v-list-item-title>
				</template>
				<v-divider class="primary"></v-divider>
				
				<v-list dense>
					<template v-for="item in displayUnknownStatusWorkers" 
					>
						<v-list-item class="pt-2 pl-1"
							:key="item.identifier" 
						>
							<v-list-item-icon class="mr-1">
								<v-badge dot offset-x="-5" offset-y="15" color="grey" ></v-badge>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-if="selectedProject==-1"  class="font-weight-regular"><b>{{item.fullName}}</b> ({{item.projectName}})</v-list-item-title>
								<v-list-item-title v-else class="font-weight-regular"><b>{{item.fullName}}</b></v-list-item-title>

								<v-list-item-subtitle>
									<span v-if="item.currentRecord != null">
										{{ item.currentRecord.time }} ({{lviews.unknown}})
									</span>
									<span v-if="item.currentRecord != null && item.nextRecord != null">
										;
									</span>
									<span v-if="item.nextRecord != null">
										{{ item.nextRecord.time }} ({{lviews.planned}} {{ getTypeName(item.nextRecord.type) }})
									</span>
								</v-list-item-subtitle>
							</v-list-item-content>

							<!-- Mobile actions -->
							<v-list-item-action class="hidden-md-and-up ma-0">
								<v-menu offset-y v-if="item.permissions.add">
									<template v-slot:activator="{ on, attrs }">
										<v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
										</template>
									<v-list dense class="pa-0">
										<v-list-item 
											class="pl-2" 
											router-link 
											:to="{name: 'addLeaving', params: {worker: item, edit: false}}"
											v-if="item.permissions.add"
										>
											<v-list-item-icon class="mr-2">
												<v-icon dense class="green--text">mdi-play-circle</v-icon>
											</v-list-item-icon>
											<v-list-item-title class="green--text">{{lviews.addEntering}}</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-list-item-action>

							<!-- Desktop -->
							<v-list-item-action class="hidden-sm-and-down ma-0">
								<v-tooltip bottom v-if="item.permissions.add">
									<template v-slot:activator="{ on, attrs }">
										<router-link :to="{name: 'addLeaving', params: {worker: item, edit: false}}">
											<v-btn
												color="secondary"
												icon
												v-bind="attrs"
												v-on="on"
											>
												<v-icon class="green--text">mdi-play-circle</v-icon>
											</v-btn>
										</router-link>
									</template>
									<span>{{lviews.addEntering}}</span>
								</v-tooltip>
							</v-list-item-action>
						</v-list-item>
						<v-divider :key="'divider-' + item.identifier"></v-divider>
					</template>
				</v-list>
			</v-list-group>
		</div>

		<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
				{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
							{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
//import WorkersAutocompleter from './WorkersAutocompleter'
const WorkersAutocompleter = () => import('./WorkersAutocompleter');
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {
	name: 'Status',

	components: {
		WorkersAutocompleter,
	},

	data: () => ({
		title: "Current presence",
		open: true,
		presentWorkers: [],
		absentWorkers: [],
		noRecordsWorkers: [],
		projects: {},
		dropdownProjects: ["All"],
		selectedProject: -1,
		selectedWorker: null,
		workersRecords: {},
		workers: {},
		errorDialog: {
			errorTitle: "Error occured",
			errorMessage: "An error occured",
			model: false
		},

		// NEW
		newProjects: [
			// {
			// 	id: <int>,
			// 	name: <String>
			// }
		],
		// The 3 below all have the same structure
		newPresentWorkers: [
			// {
			// 	identifier: workerId + ';' + projectId,
			//	visible: true,
			// 	workerId: <int>,
			// 	fullName: <String>,
			// 	projectId: <int>,
			// 	projectName: <String>,
			// 	permissions: {
			// 		add: <Boolean>,
			// 		view: <Boolean>,
			// 		change: <Boolean>,
			// 		delete: <Boolean>
			// 	},
			// 	currentRecord: {
			// 		id: <int>,
			// 		date: <String>,
			// 		time: <String>,
			// 		type: 0
			// 	},
			// 	nextRecord: {
			// 		id: <int>,
			// 		date: <String>,
			// 		time: <String>,
			// 		type: <int>
			// 	}
			// }
		],
		newAbsentWorkers: [],
		newUnknownStatusWorkers: [],

		// Allow me to explain
		// As a user your permissions are object based. Meaning your permission
		// can vary per object. Every record assigned to "presentWorkers",
		// "absentWorkers" and "unknownStatusWorkers" contains info about what 
		// permission you have to it. But you don't receive records for every
		// single worker. Some workers might not have been in worker for a few days,
		// some might be arriving for the first time. For those workers the records
		// entries (current_record, next_record) will be empty.
		// 
		// Due to that, you wouldn't be able to determine what permission as a user
		// you have to these workers on their respective projects. This is why
		// you're receiving permissions to each worker in the response, as well as
		// each project in the response.
		//
		// Based on that data you can determine whether you (as user) can add new
		// records to each worker
		workersAccess: {
			// <worker_id>: { add: <boolean>, view: <boolean>, change: <boolean>, delete: <boolean> }
		},
		projectsAccess: {
			// <project_id>: { add: <boolean>, view: <boolean>, change: <boolean>, delete: <boolean> }
		},

		displayPresentWorkers: [],
		displayAbsentWorkers: [],
		displayUnknownStatusWorkers: []
	}),
	computed: {
		lviews: { get: function () { return this.$t('views.currentPresence'); } },
		lbuttons: { get: function () { return this.$t('commons.buttons'); } },
		lmessages: { get: function () { return this.$t('commons.messages'); } },

	},
	mounted() {
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.dropdownProjects[0]=this.lviews.all
		this.$emit("set-state", "default");
		this.$emit('set-title', this.lviews.pageTitle)
		this.getGoBackLink()
		this.retrieveData();
	},
	watch: {
		projects: function(){
			this.dropdownProjects = [this.lviews.all].concat(Object.keys(this.projects))
			this.dropdownProjects.sort()
			this.setWorkerRecords()
			this.setPresentAndAbsentWorkers()			
		},
		selectedWorker: function(){
			this.setDisplayItems(this.newPresentWorkers, this.displayPresentWorkers);
			this.setDisplayItems(this.newAbsentWorkers, this.displayAbsentWorkers);
			this.setDisplayItems(this.newUnknownStatusWorkers, this.displayUnknownStatusWorkers);
		},
		selectedProject: function(){
			this.setDisplayItems(this.newPresentWorkers, this.displayPresentWorkers);
			this.setDisplayItems(this.newAbsentWorkers, this.displayAbsentWorkers);
			this.setDisplayItems(this.newUnknownStatusWorkers, this.displayUnknownStatusWorkers);
		},
	},

	methods: {
		// setTitle() {
		// 	this.$emit('set-title', this.title)
		// },
		getGoBackLink() {
			this.$emit('getGoBackLink', "");
		},
		input(item){
			this.selectedWorker=(item == null) ? null : item.workerId;
		},
		yesterdayDate(){
			let today = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
			let yesterday = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
			yesterday.setDate(today.getDate()-1)
			return yesterday
		},
		tomorrowDate(){
			let today = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
			let tomorrow = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
			tomorrow.setDate(today.getDate()+1)
			return tomorrow
		},
		setVisibleWorkerRecords(){
			let lists = [
				this.newPresentWorkers,
				this.newAbsentWorkers,
				this.newUnknownStatusWorkers
			];

			// Selected 'All', all records visible
			if (this.selectedProject == -1){
				for(let i = 0; i < lists.length; i++){
					for(let j = 0; j < lists[i].length; j++){
						lists[i][j].visible = true;
					}
				}
			}
			else{
				let item = null;
				for(let i = 0; i < lists.length; i++){
					for(let j = 0; j < lists[i].length; j++){
						item = lists[i][j];

						if (item.projectId == this.selectedProject){
							item.visible = true;
						}
						else{
							item.visible = false;
						}
					}
				}
			}
		},
		setPresentAndAbsentWorkers(){
			this.presentWorkers=[]
			this.absentWorkers=[]
			this.noRecordsWorkers=[]
			if(this.selectedWorker==null){
				if(this.workersRecords){
					for(let worker in this.workersRecords){
						for(let record of this.workersRecords[worker]){
							if(record.type==0 && record.error==0){
								this.presentWorkers.push(record)

							}
							if(record.type==1 && record.error==0 || record.error==1){
								this.absentWorkers.push(record)
							}
							else if(record.error==2){
								this.noRecordsWorkers.push(record)
							}
						}
					}
				}
			}
			else{
				if(this.workersRecords[this.selectedWorker.workerFullname]){
					for(let record of this.workersRecords[this.selectedWorker.workerFullname]){
						if(record.type==0 && record.error==0){
							this.presentWorkers.push(record)
						}
						if(record.type==1 && record.error==0 || record.error==1){
							this.absentWorkers.push(record)
						}
						else if(record.error==2){
							this.noRecordsWorkers.push(record)
						}
					}
				}
			}
			this.presentWorkers.sort(function(a,b){
				return a.worker_full_name<b.worker_full_name
			})
			this.absentWorkers.sort(function(a,b){
				return a.worker_full_name<b.worker_full_name
			})
		},


		// NEW
		setDisplayItems(source, target){
			target.splice(0, target.length);
			for (let i = 0; i < source.length; i++){
				if(
					(
						this.selectedProject == -1 
						|| this.selectedProject == source[i].projectId
					)
					&& (
						this.selectedWorker == -1 || this.selectedWorker == null
						|| this.selectedWorker == source[i].workerId
					)
				){
					target.push(source[i])
				}
			}
		},

		adaptApiResponsePresence(presenceData){
			this.newPresentWorkers.splice(0, this.newPresentWorkers.length);
			this.newAbsentWorkers.splice(0, this.newAbsentWorkers.length);
			this.newUnknownStatusWorkers.splice(0, this.newUnknownStatusWorkers.length);

			var worker = null;
			var project = null;
			var record = null;

			if(!presenceData) {
				return;
			}
			for (let i = 0; i < presenceData.length; i++){
				worker = presenceData[i];

				for (let j = 0; j < worker.projects.length; j++){
					project = worker.projects[j];

					record = {
						identifier: worker.id + ';' + project.id, 
						visible: true,
						workerId: worker.id,
						fullName: worker.full_name,
						projectId: project.id,
						projectName: project.name,
						permissions: null,
						currentRecord: null,
						nextRecord: null
					};

					// Assigning group based on current record
					if (project.current_record != null){
						record.currentRecord = {
							id: project.current_record.id,
							date: project.current_record.registered_at_date,
							time: project.current_record.registered_at_time,
							type: project.current_record.type
						};
						record.permissions = {
							add: project.current_record.add,
							view: project.current_record.view,
							change: project.current_record.change,
							delete: project.current_record.delete
						};

						switch(project.current_record.type){
							case 0:
								// present
								this.newPresentWorkers.push(record);
								break;
							case 1:
								// absent
								this.newAbsentWorkers.push(record);
								break;
							case 2:
								// Unknown
								this.newUnknownStatusWorkers.push(record);
								break;
						}
					}
					// Absent (current record missing)
					else{
						this.newAbsentWorkers.push(record);
					}
					if(project.next_record != null){
						if(!(project.current_record && project.current_record.type==0 && project.next_record.type==1 && this.timeDifference(project.current_record.registered_at_date, project.next_record.registered_at_date, project.current_record.registered_at_time, project.next_record.registered_at_time)>="24:00"))
						record.nextRecord = {
							id: project.next_record.id,
							date: project.next_record.registered_at_date,
							time: project.next_record.registered_at_time,
							type: project.next_record.type
						};
						// If current record wasn't assigned, assign permissions based on next record
						if (record.permissions == null){
							record.permissions = {
								add: project.next_record.add,
								view: project.next_record.view,
								change: project.next_record.change,
								delete: project.next_record.delete
							};
						}
					}

					// Calculate permissions if these still haven't been assigned
					if (record.permissions == null){
						let add = true; 
						let view = true;
						let change = true;
						let del = true;

						let projectPermissions = this.projectsAccess[record.projectId];
						if(typeof(projectPermissions) !== 'undefined'){
							add = projectPermissions.add && add;
							view = projectPermissions.view && view;
							change = projectPermissions.change && change;
							del = projectPermissions.delete && del;
						}
						else {
							add = false; 
							view = false;
							change = false;
							del = false;
						}

						let workerPermissions = this.workersAccess[record.workerId];
						if (typeof(workerPermissions) !== 'undefined'){
							add = workerPermissions.add && add;
							view = workerPermissions.view && view;
							change = workerPermissions.change && change;
							del = workerPermissions.delete && del;
						}
						else{
							add = false; 
							view = false;
							change = false;
							del = false;
						}

						record.permissions = {
							add: add,
							view: view,
							change: change,
							delete: del
						};
					}
				}
			}
		},
		timeDifference(date1, date2, time1, time2){
			var time_start = new Date(date1);
			var time_end = new Date(date2);
			var value_start = time1.split(':');
			var value_end = time2.split(':');
			time_start.setHours(value_start[0], value_start[1], 0, 0)
			time_end.setHours(value_end[0], value_end[1], 0, 0)
			let time = (time_end-time_start)
			let hours = Math.floor(time/1000/3600)
			let minutes = ((time%3600000)/1000/60)
			if(hours<10){
				hours="0"+hours
			}
			if(minutes<10){
				minutes="0"+minutes
			}	
			return hours+":"+minutes
		},
		adaptApiResponseWorkersAccess(workersAccess){
			this.workersAccess = {};
			this.workers = {};
			var item = null;

			if(!workersAccess) {
				return;
			}
			for(let i = 0; i < workersAccess.length; i++){
				item = workersAccess[i];
				this.workersAccess[item.id] = {
					add: item.add,
					view: item.view,
					change: item.change,
					delete: item.delete
				};
				this.workers[item.id] = item.full_name;
			}
		},
		adaptApiResponseProjectsAccess(projectsAccess){
			this.projectsAccess = {};
			this.newProjects.splice(0, this.newProjects.length);
			this.newProjects.push({
				id: -1,
				name: this.lviews.all
			});
			var item = null;

			if(!projectsAccess) {
				return;
			}
			for(let i = 0; i < projectsAccess.length; i++){
				item = projectsAccess[i];
				this.projectsAccess[item.id] = {
					add: item.add,
					view: item.view,
					change: item.change,
					delete: item.delete
				};
				this.newProjects.push({
					id: item.id,
					name: item.name
				});
			}
		},
		adaptApiResponse(apiResponse){
			if(apiResponse.projects_access){
				this.adaptApiResponseProjectsAccess(apiResponse.projects_access);
			}
			this.adaptApiResponseWorkersAccess(apiResponse.workers_access);
			this.adaptApiResponsePresence(apiResponse.presence);


			this.setDisplayItems(this.newPresentWorkers, this.displayPresentWorkers);
			this.setDisplayItems(this.newAbsentWorkers, this.displayAbsentWorkers);
			this.setDisplayItems(this.newUnknownStatusWorkers, this.displayUnknownStatusWorkers);
		},

		async retrieveData(){
			var response = null;
			this.$emit("set-state",  "LOADING_OVERLAYER");
			try{
				response = await axios({
					method: 'get',
					url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + "/api/v1/terminals/projects/cards2/",
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer " + localStorage.getItem("jwt")
					}
				});
			} catch(error){
				this.$emit("set-state",  "DEFAULT");
				if(typeof(error.response) === 'undefined'){
					this.errorDialog.errorTitle = "Network error";
					this.errorDialog.errorMessage = "Application failed to connect to the server. Please check your internet connection."
					this.errorDialog.model = true;
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('set-state', 'ERROR', "You do not have priviledges to this view");
						return;
					case 429:
						this.errorDialog.errorTitle = "Too many requests";
						this.errorDialog.errorMessage = "Server received too many reuqests from your browser. Please wait a minute before trying again.";
						this.errorDialog.model = true;
						return;
					case 400:
						this.errorDialog.errorTitle = "Request rejected";
						this.errorDialog.errorMessage = "Server rejected your request. Please make sure your application is up to date (reload the website)";
						this.errorDialog.model = true;
						return;
					case 500:
						this.errorDialog.errorTitle = "Server error";
						this.errorDialog.errorMessage = "An internal server error has occured. Please report this message to the administrator.";
						this.errorDialog.model = true;
						return;
					default:
						this.errorDialog.errorTitle = "Unknown error";
						this.errorDialog.errorMessage = "An unknown error has occured. That's all we know";
						this.errorDialog.model = true;
						return;
				}
			}

			this.adaptApiResponse(response.data);
			this.$emit("set-state",  "DEFAULT");
		},
		getTypeName(type){
			switch(type){
				case 0:
					return this.lviews.entry;
				case 1:
					return this.lviews.leave;
				default:
					return this.lviews.unknown;
			}
		}
	},
};
</script>

<style scoped>

.filterWrapper{
	padding: 10px;
	display: flex;
	width: 100%;
	align-items: center;
}

.v-select {
	max-width: 50% !important;
}

a {
	text-decoration: none;
}

</style>
